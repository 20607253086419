



































































































































































































































































































































































































































import truncate from 'vue-truncate-collapsed';
import { Component, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import scheduleModule from '@/store/modules/scheduleModule';
import Multiselect from 'vue-multiselect';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { getComponent, sleep } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import assetsModule from '@/store/modules/assetsModule';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import { WELL_CLICK_EVENT } from '@/lib/constants';
import FuzzySearch from 'fuzzy-search';
import { Debounce } from 'vue-debounce-decorator';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';

@Component({
  components: {
    truncate,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SetpointFeedbackInfoV2: () => getComponent('tasqs/SetpointFeedbackInfoV2'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    LineChart: () => import('@/lib/charts/lineChart'),
    Multiselect,
  },
})
export default class TasqHistory extends mixins(DataLoading) {
//   @Prop({ type: Boolean, required: false, default: false }) isProducing ?: boolean;

activityShown = 'comments';

searchQuery = '';

get isLoadingHistory() {
  return workflowModule.isLoadingHistory;
}

allWellEventsSelected = false;

showingCommentIDs: string[] = []

showSetpointWellHistoryDetailsPopup = false

setpointDetailsWellHistory = null

dayCommentsExpanded: string[] = []

localIsDataLoading = false;

beforeDestory() {
  tasqsListModule.setIsHistorySectionOpen(false);
}

async created() {
  this.localIsDataLoading = true;
  if (this.currentWellType == 'producing') {
    this.activityShown = 'comments';
  }
  const current_date = new Date();
  this.dayCommentsExpanded.push(current_date.toDateString());
  if (this.currentWellType == 'scheduled') {
    tasqsListModule.getTasqEventHistoryList(scheduleModule.activeComponent.ID);
    // Fetch well job history
  }
  await sleep(2000);
  this.localIsDataLoading = false;

  tasqsListModule.setIsHistorySectionOpen(true);
  this.prepareShowWellEvents();
}

@Watch('showWellEvents')
onUpdateShowWellEvents(data) {
  // console.log('object'+data);
  console.log(data);
  console.log(this.getWellHistory.findIndex((h) => h.selectEvent));

  if (data === false || this.getWellHistory.findIndex((h) => h.selectEvent) < 0) {
    this.allWellEventsSelected = this.showWellEvents;
    this.prepareShowWellEvents();
    this.dispatchHistoryEvents();
  }
}

prepareShowWellEvents() {
  this.allWellEventsSelected = this.showWellEvents;
  this.getWellHistory = this.wellHistoryEvents.map((event) => {
    const history = { ...event };
    history.hideEvent = !this.showWellEvents;
    history.selectEvent = this.showWellEvents;
    return history;
  });
}

getArrowIconForExpandedComment(isExpanded) {
  return isExpanded ? 'arrow_drop_down' : 'arrow_drop_up';
}
// {{ getArrowIconForExpandedComment(dayCommentIsExpanded(key))}}
// arrow_drop_up

getUsernameType(username) {
  if (username == null) {
    return false;
  }
  if (username.constructor.name == 'Array') {
    return `${username[0]} ${username[1]}`;
  } if (username.constructor.name == 'String') {
    return username;
  }
  return false;
}

selectCommentSectionExpand(key) {
  if (this.dayCommentsExpanded.includes(key)) {
    const index = this.dayCommentsExpanded.indexOf(key);
    if (index > -1) {
      this.dayCommentsExpanded.splice(index, 1);
    }
  } else {
    this.dayCommentsExpanded.push(key);
  }
}

dayCommentIsExpanded(key) {
  return this.dayCommentsExpanded.includes(key);
}

dateToYMD(date) {
  const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const d = date.getDate();
  const m = strArray[date.getMonth()];
  const y = date.getFullYear();
  return `${m} ${d <= 9 ? `0${d}` : d}`;
}

getCurrentTime(datetime) {
  // OR we can define like that also for current date
  // var currentDate = new Date();
  const hour = datetime.getHours();
  const meridiem = hour >= 12 ? 'PM' : 'AM';

  datetime = `${(hour + 11) % 12 + 1}:${String(datetime.getMinutes()).padStart(2, '0')} ${meridiem}`;
  return datetime;
}

get_timestamp_from_string(readable_time) {
  const mydate = new Date(`${readable_time} UTC`);

  return `${this.dateToYMD(mydate)}, ${this.getCurrentTime(mydate)}`;
}

shouldShowMoreLessCommentDetailsButton(comment) {
  if (((this.getCommentSystem(comment) !== '' && this.getCommentSystem(comment) != null) || (comment.ResponseData.SystemsComment != null && comment.ResponseData.SystemsComment != ''))
	|| ((this.getCommentSymptoms(comment) !== '' && this.getCommentSymptoms(comment) != null) || (comment.ResponseData.SymptomsComment != null && comment.ResponseData.SymptomsComment != ''))
	|| ((this.getCommentCauses(comment) !== '' && this.getCommentCauses(comment) != null) || (comment.ResponseData.CausesComment != null && comment.ResponseData.CausesComment != ''))
	|| ((this.getCommentActions(comment) !== '' && this.getCommentActions(comment) != null) || (comment.ResponseData.ActionsComment != null && comment.ResponseData.ActionsComment != ''))
  ) {
    return true;
  }
  return false;
}

get events() {
  const consolidatedCommentsDict = {};
  const allEvents = tasqsListModule.tasqEventHistoryList;

  let formResponseEvents = allEvents.filter((event) => Object.keys(event).includes('FormsResponse'));

  const OtherEvents = allEvents.filter((event) => !Object.keys(event).includes('FormsResponse') && event.readable_string && !event.readable_string.includes('Form(s) updated:'));

  if (formResponseEvents && formResponseEvents.length) {
    formResponseEvents = formResponseEvents.map((h) => h.FormsResponse).flat();
  }

  const combineEvents = formResponseEvents.concat(OtherEvents);
  // console.log(combineEvents);
  for (let x = 0; x < combineEvents.length; x++) {
    const event = combineEvents[x];

    if (!Object.keys(event).includes('title')) {
      event.isFormResponse = false;
    } else {
      event.isFormResponse = true;
      event.formResponse = event;
      event.time = event.time ? new Date(event.time).toISOString() : new Date().toISOString();
      event.readable_string = event.title || '';
    }

    // if (Object.keys(event).includes('FormsResponse')) {
    //   console.log(event);
    //   event.isFormResponse = true;
    //   event.formResponse = event.FormsResponse;
    // } else {
    //   event.isFormResponse = false;
    // }
    // if(event.readable_string && event.readable_string.includes('Form(s) updated:')){
    // 	continue;
    // }
    const time = new Date((`${event.time.replace('T', ' ')} UTC`).replace(/-/g, '/'));

    if (consolidatedCommentsDict[time.toDateString()] == null) {
      consolidatedCommentsDict[time.toDateString()] = [event];
    } else {
      consolidatedCommentsDict[time.toDateString()].push(event);
    }
  }
  let eventList: any = [];
  Object.keys(consolidatedCommentsDict).forEach((key) => {
    const updatedResult = consolidatedCommentsDict[key].map((comment) => {
      // eslint-disable-next-line no-param-reassign
      comment.key = key;
      return comment;
    });
    eventList = eventList.concat(updatedResult);
  });

  const searcher = new FuzzySearch(eventList, ['readable_time', 'readable_string', 'comment', 'time', 'type', 'key'], {
    caseSensitive: false,
  });
  let result = searcher.search(this.searchQuery);

  result = result.reduce((r, a) => {
    r[a.key] = r[a.key] || [];
    r[a.key].push(a);
    return r;
  }, Object.create(null));

  return result;
}

get wellHistoryEvents() {
  return workflowModule.wellHistoryEvents;
}

get showWellEvents() {
  return tasqProductionDataChartModule.showWellEvents;
}

getWellHistory: any = [];

updateWellHistory(data) {
  const searcher = new FuzzySearch(data, ['readableComment', 'Username', 'Comment', 'CommentType', 'time'], {
    caseSensitive: false,
  });
  const result = searcher.search(this.searchQuery);
  //   console.log(result);
  this.getWellHistory = result;
  this.dispatchHistoryEvents();
}

get activeTasq() {
  if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level == 'PAD') {
    return tasqsListModule.activeTasq;
  }
  if (tasqsListModule.activeTasq) {
    return tasqsListModule.activeTasq;
  }
  return assetsModule.activeTasq;
}

get currentWellType(): any {
  if (tasqsListModule.activeTasq && this.$route.query.type != 'producing') {
    return 'id';
  }

  if (assetsModule.activeTasq) {
    if (scheduleModule.activeComponent) {
      return 'scheduled';
    }

    return 'producing';
  }
  return this.$route.query.type;
}

get tasq() {
  if (tasqsListModule.isBatchResponding) {
    if (!tasqsListModule.activeTasq) {
      tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
    }
    return tasqsListModule.activeTasq as TasqJob;
  }

  if (assetsModule.activeTasq == undefined) {
    return tasqsListModule.activeTasq as TasqJob;
  }

  if (this.currentWellType == 'producing') {
    return assetsModule.activeTasq as TasqJob;
  }

  return tasqsListModule.activeTasq as TasqJob;
}

showWellHistorySetpointDetails(data) {
  this.showSetpointWellHistoryDetailsPopup = true;
  this.setpointDetailsWellHistory = data;
}

getWellCommentResponseIcon(comment) {
  if (comment.ResponseData == null) {
    return 'chat';
  } if (comment.ResponseData.UserResponse != null) {
    if (comment.ResponseData.UserResponse.Selection == 'YES') {
      return 'task_alt';
    } if (comment.ResponseData.UserResponse.Selection == 'NO') {
      return 'arrow_downward';
    } if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
      return 'quiz';
    } if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
      return 'quiz';
    } if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
      return 'smart_toy';
    }
  } else {
    if (comment.ResponseData.Validate == 'YES') {
      return 'task_alt';
    } if (comment.ResponseData.Validate == 'NO') {
      return 'arrow_downward';
    } if (comment.ResponseData.Validate == 'NOT_SURE') {
      return 'quiz';
    } if (comment.Validate == 'NOT_SURE') {
      return 'quiz';
    } if (comment.Validate == 'AUTO') {
      return 'smart_toy';
    }
  }
}

getReadableWellHistoryComment(comment) {
  var readableComment = 'Confirmed';
  if (comment.ResponseData == null) {
    readableComment = '';
    if (comment.Type != null && comment.Type == 'CYGNET') {
      readableComment = 'Cygnet Comment';
    }
  } else if (comment.ResponseData.UserResponse != null) {
    if (comment.ResponseData.UserResponse.Selection == 'YES') {
      var readableComment = 'Confirmed';
    } else if (comment.ResponseData.UserResponse.Selection == 'NO') {
      var readableComment = 'No';
    } else if (comment.ResponseData.UserResponse.Selection == 'NOT_SURE') {
      var readableComment = 'Not sure of';
    } else if (comment.ResponseData.UserResponse.Selection == 'AUTO') {
      var readableComment = 'Closed automatically';
    }

    if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.PredictionType && (comment.PredictionType.toLowerCase() == 'anomaly' || comment.PredictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'anomaly' || this.tasq.predictionType.toLowerCase() == 'state change' || this.tasq.predictionType.toLowerCase() == 'prolonged anomaly')) {
      readableComment += ' - Anomaly';
    } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target')) {
      readableComment += ' - Off-Target';
    } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target rt') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
      readableComment += ' - Off-Target RT';
    } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'setpoint')) {
      readableComment += ' - Setpoint';
    }

    if (comment.ResponseData.UserResponse.Selection != 'AUTO' && comment.ResponseData.UserResponse.SelectionDetails.Reason.length > 0) {
      readableComment += ': ';
      for (let x = 0; x < comment.ResponseData.UserResponse.SelectionDetails.Reason.length; x++) {
        readableComment = `${readableComment} ${comment.ResponseData.UserResponse.SelectionDetails.Reason[0]}`;
      }
    }
  } else {
    if (comment.ResponseData.Validate == 'YES') {
      var readableComment = 'Confirmed';
    } else if (comment.ResponseData.Validate == 'NO') {
      var readableComment = 'No';
    } else if (comment.ResponseData.Validate == 'NOT_SURE') {
      var readableComment = 'Not sure of';
    } else if (comment.ResponseData.Validate == 'AUTO') {
      var readableComment = 'Closed automatically';
    }
    if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target')) {
      readableComment += ' - Off-Target';
    } else if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target rt') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
      readableComment += ' - Off-Target RT';
    } else if (comment.PredictionType && comment.PredictionType.toLowerCase() == 'setpoint') {
      readableComment += ' - Setpoint';
    } else {
      readableComment += ` - ${comment.PredictionType}`;
    }
  }

  if ((comment.PredictionType && comment.PredictionType.toLowerCase() == 'off-target rt') || (this.tasq != undefined && this.tasq.predictionType.toLowerCase() == 'off-target rt')) {
    if (comment.Payload != null && comment.Payload.Outcome != null) {
      readableComment += ` - ${comment.Payload.Outcome}`;
    }
  }

  readableComment = readableComment.replace('Off-Target RT', 'Off-Target Real Time');

  return readableComment;
}

setDropdownArrowIconCommentResponse(comment_id, comment) {
  if (this.showingCommentIDs.includes(comment_id)) {
    const index = this.showingCommentIDs.indexOf(comment_id);
    if (index > -1) {
      this.showingCommentIDs.splice(index, 1);
    }
  } else {
    this.$eventBus.$emit(WELL_CLICK_EVENT, comment.CommentID);
    this.showingCommentIDs.push(comment_id);
  }
}

getDropdownArrowIconCommentResponseEnabled(comment_id, comment) {
  if (this.showingCommentIDs.includes(comment_id)) {
    //   console.log(comment.CommentID);

    return true;
  }
  return false;
}

getDropdownArrowIconCommentResponse(comment_id) {
  if (this.showingCommentIDs.includes(comment_id)) {
    return 'Hide response';
  }
  return 'See response';
}

getCommentSystem(comment) {
  let response = '';
  for (let x = 0; x < comment.ResponseData.Systems.length; x++) {
    let system_string = comment.ResponseData.Systems[x];
    const system_strings = system_string.split('<>');
    if (system_strings[0] == 'null') {
      system_string = system_strings[1];
    } else {
      system_string = `${system_strings[0]} ${system_strings[1]}`;
    }

    if (x > 0) {
      response = `${response}, ${system_string}`;
    } else {
      response += system_string;
    }
  }
  return response;
}

getCommentSymptoms(comment) {
  let response = '';
  for (let x = 0; x < comment.ResponseData.Symptoms.length; x++) {
    let symptom_string = comment.ResponseData.Symptoms[x];
    const symptom_strings = symptom_string.split('<>');
    if (symptom_strings[0] == 'null') {
      symptom_string = symptom_strings[1];
    } else {
      symptom_string = `${symptom_strings[0]} ${symptom_strings[1]}`;
    }
    if (x > 0) {
      response = `${response}, ${symptom_string}`;
    } else {
      response += symptom_string;
    }
  }
  return response;
}

getCommentCauses(comment) {
  let response = '';
  for (let x = 0; x < comment.ResponseData.Causes.length; x++) {
    let cause_string = comment.ResponseData.Causes[x];
    const cause_strings = cause_string.split('<>');
    if (cause_strings[0] == 'null') {
      cause_string = cause_strings[1];
    } else {
      cause_string = `${cause_strings[0]} ${cause_strings[1]}`;
    }
    if (x > 0) {
      response = `${response}, ${cause_string}`;
    } else {
      response += cause_string;
    }
  }
  return response;
}

getCommentActions(comment) {
  let response = '';
  for (let x = 0; x < comment.ResponseData.Actions.length; x++) {
    let action_string = comment.ResponseData.Actions[x];
    const action_strings = action_string.split('<>');

    if (action_strings.length < 2) {
      action_strings.push('');
    }

    if (action_strings[0] == 'null') {
      action_string = action_strings[1];
    } else {
      action_string = `${action_strings[0]} ${action_strings[1]}`;
    }
    if (x > 0) {
      response = `${response}, ${action_string}`;
    } else {
      response += action_string;
    }
  }
  return response;
}

toggleShowActivity(activity) {
  this.activityShown = activity;
}

updateHistory(comment) {
  const historyList = this.getWellHistory.map((event) => {
    const history = { ...event };
    if (comment && comment.CommentID === history.CommentID) {
      history.hideEvent = !history.selectEvent;
    }

    return history;
  });
  setTimeout(() => {
    this.getWellHistory = historyList;
    this.dispatchHistoryEvents();
  }, 500);
}

dispatchHistoryEvents() {
  // console.log(this.getWellHistory.map((h) => h.selectEvent));
  const anyActiveHistory = this.getWellHistory.filter((h) => !h.hideEvent);
  // console.log(this.getWellHistory);
  this.$eventBus.$emit('history-search-updated', anyActiveHistory);
}

@Debounce(1000)
@Watch('searchQuery')
updateSearchQuery(data) {
  const searcher = new FuzzySearch(data, ['readableComment', 'Username', 'Comment', 'CommentType', 'time'], {
    caseSensitive: false,
  });
  const result = searcher.search(this.searchQuery);
  //   console.log(result);
  this.getWellHistory = result;
  this.dispatchHistoryEvents();
}

toggleEventsSelection() {
  // console.log('toogle selection');
  this.allWellEventsSelected = !this.allWellEventsSelected;
  // tasqProductionDataChartModule.setShowWellEvents(this.allWellEventsSelected);
  this.getWellHistory = this.getWellHistory.map((h) => {
    h.selectEvent = this.allWellEventsSelected;
    h.hideEvent = !this.allWellEventsSelected;
    return h;
  });
  this.dispatchHistoryEvents();
}
}

